/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";
import tw, { css } from "twin.macro";
import Grid from "~styles/Grid.jsx";
import * as A from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { ArticleGrid, LineSwipe, WaveCanvas } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowDown } from "~assets/svg/arrow-down.svg";
import { ReactComponent as ArrowReset } from "~assets/svg/arrow-reset.svg";

const ArticleMasonrySection = ({
  data: { backgroundColor, fontColor, heading, body }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [authors, setAuthors] = useState([]);
  const [activeAuthor, setActiveAuthor] = useState(null);
  const [topics, setTopics] = useState([]);
  const [activeTopic, setActiveTopic] = useState(null);

  const [urlTopic] = useQueryParam(`topic`, StringParam);
  const [urlAuthor] = useQueryParam(`author`, StringParam);

  // ---------------------------------------------------------------------------
  // variables

  let iconColor;
  let buttonColor = `black`;

  switch (backgroundColor?.title?.toLowerCase()) {
    case `white`:
    case `grey`:
    case `gray`:
    case `faint blue`:
      iconColor = `#84bbd9`;
      break;

    case `light blue`:
      iconColor = getColor(`white`);
      break;

    default:
      iconColor = `#84bbd9`;
      break;
  }

  switch (fontColor?.title?.toLowerCase()) {
    case `white`:
      buttonColor = `white`;
      break;

    default:
      buttonColor = `black`;
      break;
  }

  // ---------------------------------------------------------------------------
  // methods

  const reset = () => {
    setActiveAuthor(`By author`);
    setActiveTopic(`By topic`);
  };

  const articleData = useStaticQuery(graphql`
    query Articles {
      allSanityArticle {
        nodes {
          _type

          slug {
            current
          }
          published

          id
          title
          excerpt
          author

          cardImage {
            asset {
              gatsbyImageData(width: 1024, placeholder: NONE)
            }
          }

          tag {
            title
            hex
          }

          topic {
            title
          }
          date
        }
      }
    }
  `);

  const articles = articleData?.allSanityArticle?.nodes;
  const filteredArticles = articles?.filter(
    (article) => article.published === true
  );
  filteredArticles.sort((a, b) => {
    return b.date?.replace(/-/g, ``) - a.date?.replace(/-/g, ``);
  });
  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setActiveAuthor(urlAuthor);
    setActiveTopic(urlTopic);
  }, [urlTopic, urlAuthor]);

  useEffect(() => {
    if (!filteredArticles?.[0]) {
      return;
    }

    const allAuthors = [];
    const allTopics = [];

    filteredArticles?.forEach((article) => {
      if (!article?.author || !article?.topic?.title) {
        return;
      }

      const parsedAuthor = article.author.trim();
      const parsedTopic = article.topic.title.trim();

      if (parsedAuthor && !allAuthors.includes(parsedAuthor)) {
        allAuthors.push(parsedAuthor);
      }

      if (parsedTopic && !allTopics.includes(parsedTopic)) {
        allTopics.push(parsedTopic);
      }
    });

    setAuthors(allAuthors);
    setTopics(allTopics);
  }, [articles]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <div
        id={heading?.replace(/\s+/g, `-`).toLowerCase()}
        css={[
          css`
            background: ${backgroundColor?.hex};
            color: ${fontColor?.hex};

            @media screen and (min-width: 1025px) {
              height: 660px;
            }

            @media screen and (min-width: 1280px) {
              height: 720px;
            }

            @media screen and (min-width: 1440px) {
              height: 800px;
            }
          `,
          tw`w-full relative pt-48 md:pt-64 pb-16 md:pb-8 lg:pb-12 xl:pb-20`
        ]}
      >
        <WaveCanvas />

        <Grid _css={[tw`z-20`]}>
          <header
            css={[
              css`
                //
              `,
              tw`col-span-12 md:col-span-9 lg:col-span-8 mb-12 md:mb-16 relative flex flex-col`
            ]}
          >
            <div
              css={[
                css`
                  //
                `,
                tw`relative flex items-center mb-12 md:mb-6 lg:mb-12`
              ]}
            >
              <T.Head
                _css={[
                  css`
                    ${A.Keyframes(`appearDown`, {
                      delay: `300ms`,
                      settings: `1s ${A.EASING_CUBIC} forwards`
                    })}
                  `,
                  tw`whitespace-pre-wrap`
                ]}
                font="4"
                level="1"
              >
                <span dangerouslySetInnerHTML={{ __html: heading }} />
              </T.Head>

              <div css={[tw`w-32 relative block ml-5`]}>
                <LineSwipe color={iconColor} delay={500} duration={2000} />
              </div>
            </div>

            <T.Head
              _css={[
                css`
                  ${A.Keyframes(`appearDown`, {
                    delay: `500ms`,
                    settings: `1s ${A.EASING_CUBIC} forwards`
                  })}
                `,
                tw`whitespace-pre-wrap font-bold`
              ]}
              font="1"
              level="1"
            >
              <span dangerouslySetInnerHTML={{ __html: body }} />
            </T.Head>
          </header>
        </Grid>

        <div css={[tw`relative z-20`]}>
          <Grid>
            <article
              css={[
                css`
                  color: ${buttonColor.hex};
                `,
                tw`col-span-12 md:col-span-4 md:col-start-1 relative`
              ]}
            >
              <select
                css={[
                  css`
                    appearance: none;
                  `,
                  tw`w-full h-14 relative block cursor-pointer mb-4 md:mb-0 px-4 text-b1 md:text-b1-md text-black font-bold border`
                ]}
                onChange={(e) => {
                  setActiveTopic(e.currentTarget.value);
                }}
                value={activeTopic}
              >
                <option selected value="by topic">
                  By topic
                </option>

                {topics.map((topic) => (
                  <option key={`topic-${topic}`} value={topic}>
                    {topic}
                  </option>
                ))}
              </select>

              <div
                css={[
                  tw`w-14 h-14 absolute top-0 right-0 bottom-0 z-10 flex items-center justify-center pointer-events-none`
                ]}
              >
                <ArrowDown css={[tw`w-4`]} />
              </div>
            </article>

            <article
              css={[
                css`
                  color: ${buttonColor.hex};
                `,
                tw`col-span-12 md:col-span-4 relative`
              ]}
            >
              <select
                css={[
                  css`
                    appearance: none;
                    padding-right: 3rem;
                  `,
                  tw`w-full h-14 relative block cursor-pointer px-4 text-b1 md:text-b1-md text-black font-bold border`
                ]}
                onChange={(e) => {
                  setActiveAuthor(e.currentTarget.value);
                }}
                value={activeAuthor}
              >
                <option selected value="by author">
                  By author
                </option>

                {authors.map((author) => (
                  <option key={`author-${author}`} value={author}>
                    {author}
                  </option>
                ))}
              </select>

              <div
                css={[
                  tw`w-14 h-14 absolute top-0 right-0 bottom-0 z-10 flex items-center justify-center pointer-events-none`
                ]}
              >
                <ArrowDown css={[tw`w-4`]} />
              </div>
            </article>

            <article
              css={[
                css`
                  color: ${buttonColor.hex};
                `,
                tw`col-span-12 md:col-span-4 relative mt-6 md:mt-0`
              ]}
            >
              <div css={[tw`w-full relative flex justify-end`]}>
                <button
                  type="button"
                  css={[
                    css`
                      ${MEDIA_QUERIES.hoverable} {
                        &:hover {
                          background: ${getColor(`blue-turq`)};
                          color: white;

                          svg {
                            fill: white;
                          }
                        }
                      }
                    `,
                    tw`w-48 h-12 relative block px-6 bg-white border text-black`
                  ]}
                  onClick={() => {
                    reset();
                  }}
                >
                  <div
                    css={[
                      tw`w-full relative flex items-center justify-between`
                    ]}
                  >
                    <T.Body _css={[tw`font-bold`]} font="3">
                      Reset filters
                    </T.Body>

                    <ArrowReset css={[tw`w-4`]} />
                  </div>
                </button>
              </div>
            </article>
          </Grid>
        </div>
      </div>

      <section
        css={[
          css`
            background: white;
            color: ${fontColor?.hex};
          `,
          tw`pt-24 md:pt-24 pb-20 md:pb-20`
        ]}
      >
        <ArticleGrid
          articles={filteredArticles}
          filters={{
            activeAuthor,
            activeTopic
          }}
        />
      </section>
    </>
  );
};

export default ArticleMasonrySection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ArticleMasonrySectionFragment on SanityArticleMasonrySection {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    body
    heading
  }
`;
